import styled, { keyframes } from "styled-components";

import { Link } from "react-router-dom";

export const CasLogo = styled.div`
  display: flex;
  background: #fff;
  justify-content:center
  width: 200px;
  height: 100px;
  text-decoration: none;
  margin-top: 9px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 8px; */
  img {
    position: relative;
    width: 150px;
    height: 75px;
    object-fit: contain;
    margin: auto;
    
    //margin-left: 400px;
  }
  
`;
// export const brandscontainer = styled.div`
//   /* .slick-slider {
//     margin-top: 20px;
//   } */
// `;
