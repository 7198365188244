import "./Clients.css";

import React, { useEffect } from "react";

import AbuDhabi from "../../images/Oil_Gas/AbudhabiGas.jpg";
import AbuEljadayel from "../../images/Food_Beverages/Abueljadayel.png";
import Adidas from "../../images/Trade_services/adidas.png";
import Amoun from "../../images/Pharama/Amoun.png";
import Arabiat from "../../images/Automotive/Arabiat.jpg";
import Arish from "../../images/Public_Sector/ArishCement.jpg";
import Awazel from "../../images/Building_Materials/Awazel.jpg";
import Best from "../../images/Trade_services/Best.png";
import Cemex from "../../images/Building_Materials/Cemex.jpg";
import Daem from "../../images/Mej.png";
import Dawaa from "../../images/Pharama/AlDawaa-Logo.jpg";
import Dolphin_Energy from "../../images/Oil_Gas/Dolphin_Energy.png";
import EGPC from "../../images/Oil_Gas/EGPC.png";
import Elaj from "../../images/Pharama/Elaj.jpg";
import Eltarek from "../../images/Automotive/Eltarek.jpg";
import GasFrance from "../../images/Oil_Gas/GasDefrance.png";
import Juhayna from "../../images/Food_Beverages/Juhayna.gif";
import MadinetNasr from "../../images/Public_Sector/MadinetNasr.png";
import Mansour from "../../images/Automotive/Mansour.png";
import Petrobell from "../../images/Oil_Gas/Petrobell.jpg";
import Pirell from "../../images/Trade_services/Pirell.png";
import Prima from "../../images/Building_Materials/Prima.jpg";
import RasELkhima from "../../images/Public_Sector/RasELkhima.png";
import Royal from "../../images/Building_Materials/Royal.jpg";
import Sanoufi from "../../images/Pharama/Sanoufi.png";
import SaudiElectric from "../../images/Public_Sector/SaudiElectric.jpg";
import Shar from "../../images/Shar.png";
import Spinneys from "../../images/Trade_services/Spinneys.jpg";
import Sudapet from "../../images/Oil_Gas/Sudapet.jpg";
import TetraPark from "../../images/Trade_services/TetraPark.jpg";
import Uci from "../../images/Food_Beverages/Uci.png";
import Ufi from "../../images/Food_Beverages/Ufi.png";
import Universal from "../../images/Trade_services/Universal.png";
import WhiteWhale from "../../images/Trade_services/WhiteWhale.jpg";
import betterHome from "../../images/BetterHome.jpg";
import logo from "../../images/R&N.png";
import logo1 from "../../images/TMG.png";
import logo10 from "../../images/Iwan.png";
import logo11 from "../../images/MemaarMorshedy.png";
import logo2 from "../../images/ABB.png";
import logo3 from "../../images/Asaied.png";
import logo4 from "../../images/MisrItalia.png";
import logo5 from "../../images/Amer.png";
import logo7 from "../../images/SaudiI.png";
import logo9 from "../../images/M2.png";

//Real Estate

//Pharma

// import logo from "../../images/R&N.png";
// import logo1 from "../../images/TMG.png";

const ANIMATEDCLASSNAME = "animated";
const ELEMENTS_SPAN = [];

const Clients = () => {
  useEffect(() => {
    const ELEMENTS = document.querySelectorAll(".HOVER");
    ELEMENTS.forEach((element, index) => {
      let addAnimation = false;
      // Elements that contain the "FLASH" class, add a listener to remove
      // animation-class when the animation ends
      if (element.classList[1] == "FLASH") {
        element.addEventListener("animationend", (e) => {
          element.classList.remove(ANIMATEDCLASSNAME);
        });
        addAnimation = true;
      }

      // If The span element for this element does not exist in the array, add it.
      if (!ELEMENTS_SPAN[index])
        ELEMENTS_SPAN[index] = element.querySelector("span");

      element.addEventListener("mouseover", (e) => {
        ELEMENTS_SPAN[index].style.left = e.pageX - element.offsetLeft + "px";
        ELEMENTS_SPAN[index].style.top = e.pageY - element.offsetTop + "px";

        // Add an animation-class to animate via CSS.
        if (addAnimation) element.classList.add(ANIMATEDCLASSNAME);
      });

      element.addEventListener("mouseout", (e) => {
        ELEMENTS_SPAN[index].style.left = e.pageX - element.offsetLeft + "px";
        ELEMENTS_SPAN[index].style.top = e.pageY - element.offsetTop + "px";
      });
    });

    return () => {};
  }, []);

  return (
    <>
      <div className="container">
        <h1 className="text-center  fs-1 text-primary mt-5">Our Clients</h1>
        <div className="fs-4 text-black mt-3 mb-5 p-3">
          Over the years, we have conjured up a list of clients from various
          industries. We've worked with them on projects in SAP solutions, MES
          and related services. Our clients are titans, each in their own
          sector.
        </div>

        <div className=" d-lg-flex flex-wrap p-4 mt-2 mb-4 justify-content-center ">
          <h2 className="text-center fs-2 text-secondary mt-4 mb-4">
            Real Estate, Engineering and Construction
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img className="Brand_img" src={logo1}></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo2}></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo3}></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img className="Brand_img" src={logo4}></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo5}></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  style={{ marginTop: "-30px" }}
                  src={Shar}
                  alt="Shar"
                ></img>
              </text>
            </a>

            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo7}></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  style={{ marginTop: "-30px" }}
                  src={betterHome}
                  alt="Better Home"
                ></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  style={{ marginTop: "-30px" }}
                  src={Daem}
                  alt="Daem"
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo}></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo10}></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo11}></img>
              </text>{" "}
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img className="Brand_img" src={logo9}></img>
              </text>{" "}
            </a>
          </div>
          <h2 className="text-center fs-2 text-secondary mt-5 mb-2">
            Pharmaceuticals
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4 p-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img className="Brand_img" src={Dawaa} alt="AL-Dawaa"></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Amoun}
                  alt="Amoun"
                  style={{ marginTop: "-20px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={Sanoufi} alt="Sanoufi"></img>
              </text>
            </a>

            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  style={{ marginTop: "-35px" }}
                  src={Elaj}
                  alt="Elaj"
                ></img>
              </text>
            </a>
          </div>
          <h2 className="text-center fs-2 text-secondary mt-5">Oil and Gas</h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  src={AbuDhabi}
                  alt="Abu Dhabi Gas"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Dolphin_Energy}
                  alt="Dolphin Energy"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={EGPC}
                  alt="EGPC"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={GasFrance}
                  alt="Gas de France"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Petrobell}
                  alt="PetroBel"
                  style={{ marginTop: "-5px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Sudapet}
                  alt="Sudapet"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>{" "}
            </a>
          </div>
          <h2 className="text-center fs-2 text-secondary mt-5">
            Public Sector
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  src={Arish}
                  alt="Arish Cement"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={MadinetNasr}
                  alt="Madinet Nasr"
                  style={{ marginTop: "-15px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={RasELkhima}
                  alt="Ras El Khima"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={SaudiElectric}
                  alt="Saudi Electric"
                  style={{ marginTop: "-15px" }}
                ></img>
              </text>{" "}
            </a>
          </div>
          <h2 className="text-center fs-2 text-secondary mt-5">
            Building Materials
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  src={Awazel}
                  alt="Awazel"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Cemex}
                  alt="Cemex"
                  style={{ marginTop: "-15px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Prima}
                  alt="Prima"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Royal}
                  alt="Royal"
                  style={{ marginTop: "-25px" }}
                ></img>
              </text>{" "}
            </a>
          </div>

          <h2 className="text-center fs-2 text-secondary mt-5">
            Food & Beverages
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  src={AbuEljadayel}
                  alt="Abu El jadayel"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Juhayna}
                  alt="Juhayna"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Uci}
                  alt="Uci"
                  style={{ marginTop: "-15px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Ufi}
                  alt="UFI"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>{" "}
            </a>
          </div>
          <h2 className="text-center fs-2 text-secondary mt-5 ">
            Trade, Services and Automotive
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-5">
            <a className="HOVER ">
              <span></span>

              <text>
                <img
                  className="Brand_img"
                  src={Adidas}
                  alt="Adidas"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Best}
                  alt="Best"
                  style={{ marginTop: "-5px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Spinneys}
                  alt="Spinneys"
                  style={{ marginTop: "-15px" }}
                ></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={TetraPark}
                  alt="Tetra Park"
                  style={{ marginTop: "-5px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Universal}
                  alt="Universal"
                  style={{ marginTop: "-5px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={WhiteWhale}
                  alt="White Whale"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Pirell}
                  alt="Pirell"
                  style={{ marginTop: "5px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Arabiat}
                  alt="Arabiat"
                  style={{ marginTop: "5px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Eltarek}
                  alt="Eltarek"
                  style={{ marginTop: "-15px" }}
                ></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img
                  className="Brand_img"
                  src={Mansour}
                  alt="Mansour"
                  style={{ marginTop: "-35px" }}
                ></img>
              </text>{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clients;

{
  /* <h2 className="text-center fs-2 text-secondary mt-5">Automotive</h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img className="Brand_img" src={logo1}></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo2}></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo3}></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img className="Brand_img" src={logo4}></img>
              </text>{" "}
            </a>
          </div> */
}
{
  /* <h2 className="text-center fs-2 text-secondary mt-5">
            Higher education and Banking
          </h2>
          <div className="display border-0 me-lg-4 mb-lg-0 mb-4">
            <a className="HOVER ">
              <span></span>

              <text>
                <img className="Brand_img" src={logo1}></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo2}></img>
              </text>
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo3}></img>
              </text>
            </a>
            <a className="HOVER ">
              <span></span>
              <text>
                <img className="Brand_img" src={logo4}></img>
              </text>{" "}
            </a>
            <a className="HOVER">
              <span></span>
              <text>
                <img className="Brand_img" src={logo5}></img>
              </text>{" "}
            </a>
</div>} */
}
