import {
  Aboutsection,
  Img,
  Img2Wrapper,
  LeaderHeading,
  MainTitle,
  MemberImg,
  MemberName,
  MemberProfile,
  MemberTitle,
  Para,
  TeamCol,
  TeamRow,
  TeamSection,
  TextWrapper,
} from "./AboutInfo";

import Amin from "../../images/People/amin.jpg";
import { Container } from "../../globalStyles";
import Eman from "../../images/People/Eman_Sa3eed.jpg";
import MAbdelazim from "../../images/People/Mahmoud_AbdulAziem.JPG";
import Our_Leadership from "../../components/AboutComponents/Our_Leadership";
import React from "react";
import about3 from "../../images/AboutMain.png";
import logo from "../../images/user.png";
import khairy from "../../images/People/khairy.jpg";
import dabbagh from "../../images/People/m.dabbagh.jpg";
import zaher from "../../images/People/zaher.jpg";
import maha from "../../images/People/maha.jpg";

// import logo2 from "../../images/hima.jpg";

// import Manager from "../../images/Mr_ibrahim";
//import { InfoColumn } from '../../components/InfoSection/InfoSection.elements';

function About() {
  return (
    <>
      <Aboutsection>
        <Container>
          <Img2Wrapper data-aos={"fade-left"} data-aos-duration={"2000"}>
            <Img src={about3} alt="about image" />
          </Img2Wrapper>
          {/* <div className="  fs-1 text-left text-primary">
            About <strong>us</strong>
          </div> */}

          <TextWrapper
            className="col-10 "
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <Para>
              CIC is a leading IT consulting firm providing expertise to
              companies with Enterprise Resources planning applications (SAP).{" "}
            </Para>
            <Para>
              At CIC, we deliver results that add value to your organization
              through your IT products. These results are the outcome of proven
              experience and effective solutions. CIC is the first SAP local
              partner with three categories of partnership with SAP; Service
              Partnership (Large Enterprises), and Channel Partnership (Small
              and Midsize Businesses).
            </Para>
            <Para>
              The basis of our partnerships with our clients is our commitment
              to world-class service, high-quality results, the open sharing of
              knowledge and innovation. We strive to help build a complete and
              comprehensive business solution around your SAP system. Efficient
              business modeling alongside the latest development techniques are
              implemented into our methodology and are used by our experienced
              consultants. Our Business Process Driven approach ensures the
              quality of our implementation method.
            </Para>
          </TextWrapper>

          <LeaderHeading className=" text-primary">
            Meet Our <strong>Leadership</strong>{" "}
          </LeaderHeading>
          <Our_Leadership />

          <TeamRow>
            <TeamCol>
              <MainTitle className=" text-primary">
                <p>
                  Meet Our<strong>Executioners</strong>
                </p>
              </MainTitle>
              <TeamSection>
                <MemberProfile data-aos="fade-up" data-aos-duration="1000">
                  <MemberImg>
                    <img
                      src={Amin}
                      alt="amin al moussawel"
                      className="img-circle img-fluid img"
                    />
                    <MemberName>
                      <p>Amin Al Moussawel</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Head of Sales & <br></br>Business Development</p>
                    </MemberTitle>
                  </MemberImg>
                </MemberProfile>

                <MemberProfile data-aos="fade-up" data-aos-duration="1000">
                  <MemberImg>
                    <img
                      src={dabbagh}
                      alt="cic_executioner"
                      className="img-circle img-fluid img"
                    />
                    <MemberName>
                      <p>Mohammed Dabbagh</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Finance Delivery Manager</p>
                    </MemberTitle>
                  </MemberImg>
                </MemberProfile>

                <MemberProfile data-aos="fade-up" data-aos-duration="1000">
                  <MemberImg>
                    <img
                      src={zaher}
                      alt="cic_executioner"
                      className="img-circle img-fluid img"
                    />
                    <MemberName>
                      <p>Zaher Akram</p>
                    </MemberName>
                    <MemberTitle>
                      <p>Logistics Delivery Manager</p>
                    </MemberTitle>
                  </MemberImg>
                </MemberProfile>
                <MemberProfile data-aos="fade-up" data-aos-duration="1000">
                  <MemberImg>
                    <img
                      src={khairy}
                      alt="portrait"
                      className="img-circle img-fluid img"
                    />
                    <MemberName>
                      <p>Khairy Soliman </p>
                    </MemberName>
                    <MemberTitle>
                      <p>Real Estate Delivery Manager</p>
                    </MemberTitle>
                  </MemberImg>
                </MemberProfile>
                <MemberProfile data-aos="fade-up" data-aos-duration="1000">
                  <MemberImg>
                    <img
                      src={maha}
                      alt="portrait"
                      className="img-circle img-fluid img"
                    />
                    <MemberName>
                      <p>Maha Nageeb</p>
                    </MemberName>
                    <MemberTitle>
                      <p>HR Manager</p>
                    </MemberTitle>
                  </MemberImg>
                </MemberProfile>
              
              </TeamSection>
            </TeamCol>
          </TeamRow>
        </Container>
      </Aboutsection>
    </>
  );
}

export default About;
