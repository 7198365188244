// import React, { useRef } from 'react';

import emailjs from "emailjs-com";

export const sendMail = (
  e,
  to_name,
  email,
  phone_no,
  company,
  subject,
  message
) => {
  // e.preventDefault();
  e.stopPropagation();
  // company_name
  console.log(company);
  console.log(phone_no);

  const templateParams = {
    to_name,
    email,
    phone_no,
    company,
    subject,
    message,
  };

  return emailjs
    .send(
      "service_7a2r1z1",
      "template_6b1k16d",
      templateParams,
      "user_XWNENXUTHnY9eVs75KrHC"
    )
    .then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
};
