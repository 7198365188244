import styled from "styled-components";

export const Aboutsection = styled.div`
  display: flex;
  color: #000;
  padding: 100px 0 100px;
  background: ${({ lightbg }) => (lightbg ? "#101522" : "#fff")};
`;

export const MainTitle = styled.h2`
  display: flex;
  position: relative;
  color: #1ea8e9;
  font-size: 40px;
  font-weight: 500;
  /* font-family: "Lato", sans-serif; */
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  line-height: 1.1;

  // color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#1c2237")};
  strong {
    margin-left: 10px;
  }

  @media only screen and (max-width: 720px) {
    font-size: 40px;
    text-align: center;
    /* justify-content: start; */
    vertical-align: baseline;
    /* position: absolute; */
  }
`;

export const InfoRow = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  flex-direction: "row";
  justify-content: center;
`;
export const Para = styled.p`
  // position: relative;
  display: flex;
  margin: 20px;
  width: 100%;
  color: black;
  font-weight: 300;
  font-size: 22px;
  line-height: 24px;

  @media only screen and (max-width: 600px) {
    margin: 20px 0;
  }
`;
export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 60px;
  margin: auto;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;
export const Img2 = styled.img`
  /* padding-right: 0;
  border: 0; */
  width: 100%;
  padding: 10px;
  display: flex;
  object-fit: contain;
  /* max-height: 500px; */
`;
export const Img = styled.img`
  width: 100%;
  /* height: 500px; */
  padding: 0px;
  margin-bottom: 50px;
  object-fit: contain;
`;

export const ImgWrapper = styled.div`
  /* position: absolute; */
  /* right: 500px; */
  max-width: 550px;
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 30px;

  //flex-wrap: wrap;
`;
export const Img2Wrapper = styled.div`
  /* position: absolute; */
  display: flex;
  max-width: 1000px;

  margin: auto;
`;

export const Para1 = styled.p`
  // position: relative;

  max-width: 400px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  /*  */
  color: #000 //flex-wrap: wrap;
    strong {
    /* font-family: 'Lato', sans-serif; */
  }
`;

export const Para2 = styled.p`
  //position: relative;
  max-width: 400px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  /* font-family:'Lato', sans-serif; */
  font-weight: 400;
  color: #000;
  // align-items: flex-end;
`;

//------------------------------------------------------our Team
export const TeamRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -10px;   
    margin-right: -10px; */
`;
export const TeamCol = styled.div`
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 100%;
  max-width: 100%;
`;

export const TeamSection = styled.div`
  margin-top: 4.5rem;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const MemberProfile = styled.div`
  /* width: 100%; */
  max-width: 20.2rem;
  margin: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.25;
`;
export const MemberImg = styled.div`
  .img-circle {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .img-fluid {
    display: block;
    //  max-width: 100%;
    //  height: auto;
  }
  .img {
    margin: 10px;
    max-width: 100%;
  }
`;
export const MemberName = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #555f77;
`;
export const MemberTitle = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #848ea1;
`;
//---------------------------------------------- our leadership

export const LeaderHeading = styled.h1`
  color: #1ea8e9;
  font-size: 40px;
  /* font-family: "Lato", sans-serif; */
  font-weight: 100;
  margin-bottom: 10px;
  align-items: center;
  text-align: center;
`;
