import "../Products/ProductsPage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";

import { Container } from "../../globalStyles";
import { InfoSection } from "../../components";
import Slider from "react-slick";
import { homeObjOne } from "./Data";

// import "bootstrap/dist/css/bootstrap.min.css";

function Products(props) {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <Container className="mb-5 carousel ">
      <InfoSection
        {...homeObjOne}
        lightbg={!props.isDarkMode}
        lightText={props.isDarkMode}
        lightTextDesc={props.isDarkMode}
      />

      {/* <div className="text-center  fs-1 text-primary mt-4">
        <h1>Our Products</h1>
      </div> */}
      <Slider {...settings}>
        <div className="box">
          <div className="our-products settings ">
            <div className="icon">
              {" "}
              <img src="https://i.imgur.com/6NKPrhO.png" />{" "}
            </div>
            <h4 className="fs-5">CIC's Customer Centric Real Estate XCCRE</h4>
            <p className="text-start">
              XCCRE is a mobile application built above SAP RE Engine using
              latest Fiori interface technologies. This allows xCCRE to be
              accessible Anywhere, Anytime, on Any device (laptops, tablets and
              smart phones).
            </p>
          </div>
        </div>

        <div className="box">
          <div className="our-products speedup">
            <div className="icon">
              {" "}
              <img src="https://i.imgur.com/KMbnpFF.png" />{" "}
            </div>
            <h4 className="fs-5">CIC's Mobile Warehouse Management Solution</h4>
            <p className="text-start">
              Mobile Warehouse Management is a Mobile integration to SAP system
              that enables warehouse users to carry out SAP update transactions
              remotely off a terminal, and to record material movements as they
              are carried out.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="our-products privacy">
            <div className="icon">
              {" "}
              <img src="https://i.imgur.com/AgyneKA.png" />{" "}
            </div>
            <h4 className="fs-5">CIC's Mobile Van Sales</h4>
            <p className="text-start">
              CIC's Mobile Van Sales Solution is designed to supports mobile
              users to log all tour-related activities using mobile devices
            </p>
          </div>
        </div>

        <div className="box">
          <div className="our-products backups">
            <div className="icon">
              {" "}
              <img src="https://i.imgur.com/vdH9LKi.png" />{" "}
            </div>
            <h4 className="fs-5">CIC's Manpower Solution</h4>
            <p className="text-start">
              CIC's Manpower application can handle full manpower cycle from
              estimation to billing the customer.{" "}
            </p>
          </div>
        </div>

        <div className="box">
          <div className="our-products ssl">
            <div className="icon">
              {" "}
              <img src="https://i.imgur.com/v6OnUqu.png" />{" "}
            </div>
            <h4 className="fs-5">
              CIC's Home Appliances With Mobile Warehouse Management
            </h4>
            <p className="text-start">
              Full-fledged industry specific implementation for home appliances
              manufacturing ERP based on SAP ECC 6.0 and the booming Mobile
              technology for material management and inventory accuracy.
            </p>
          </div>
        </div>

        <div className="box">
          <div className="our-products database">
            <div className="icon">
              {" "}
              <img src="https://i.imgur.com/VzjZw9M.png" />{" "}
            </div>
            <h4 className="fs-5">
              CIC's Fabricated Metals Rapid Deployment Solution
            </h4>
            <p className="text-start">
              Certified IT Consultants offers midmarket fabricated metals
              company a foundational, pre-configured ERP solution that can
              lessen risk, shorten implementation efforts, and accelerate
              time-to-value.
            </p>
          </div>
        </div>
      </Slider>
    </Container>
  );
}
export default Products;
