import styled from "styled-components";

//Counter Card
export const CounterHolder = styled.div`
  color: ${({ lightbg }) => (lightbg ? "#101522" : "#fff")};

  // background-size: cover;

  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${({ lightbg }) => (lightbg ? "#f8f8f8" : "#101522")};
  transition: all 0.6s ease;
  padding-bottom: 120px;
  padding-top: 120px;

  .counters-item-value {
    background-image: linear-gradient(180deg, #006dff 70%, #080080);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
    .counters-item-value {
      color: black;
    }
  }
`;
export const Col_row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 33% 33% 33%;

  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
  }
`;

export const Counters_item = styled.div`
  /* position: relative; */
  opacity: 1;
  transition: opacity 0.3s ease-out;
  // margin: 20px;
  font-size: 20px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  line-height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* margin-bottom: 20px; */
  margin: auto;
  img {
    width: 200px;
  }
  @media screen and (max-width: 960px) {
    img {
      width: 100px;
    }
    font-size: 14px;
  }
`;

export const Counters_value = styled.div`
  // background-image: linear-gradient(180deg,#000,#000);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  //color: transparent;
  display: inline;
  /* margin-bottom: 5px;
  margin-top: 10px; */
`;

export const Counters_numbervalue = styled.span`
  font-size: 40px;
  font-weight: 700;
  // line-height: 48px;
  /* margin: 80px 0 20px; */
  padding: 0;
  @media screen and (max-width: 960px) {
    
     font-size : 24px;
     font-weight: 700;
    
`;

export const Counters_caption = styled.div`
  display: block;
`;
