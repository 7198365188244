import { useEffect, useState } from "react";

import { sendMail } from "./Mail";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    username: "",
    email: "",
    phone: "",
    company_name: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  // values.company_name,
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("inn 1");
    setErrors(validate(values));

    if (Object.keys(validate(values)).length === 0)
      sendMail(
        e,
        values.username,
        values.email,
        values.phone,
        values.company_name,
        values.subject,
        values.message
      );
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  return { handleChange, handleSubmit, values, errors };
};

export default useForm;
