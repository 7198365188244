import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Adidas from "../../images/Trade_services/adidas.png";
import { CasLogo } from "./BrandCarousel.elements";
import Eltarek from "../../images/Automotive/Eltarek.jpg";
import Iwan from "../../images/Iwan.png";
import Juhayna from "../../images/Food_Beverages/Juhayna.gif";
import Memaar from "../../images/MemaarMorshedy.png";
import Pirell from "../../images/Trade_services/Pirell.png";
import R_N from "../../images/R&N.png";
import React from "react";
import Sanoufi from "../../images/Pharama/Sanoufi.png";
import Slider from "react-slick";
import Spinneys from "../../images/Trade_services/Spinneys.jpg";
import TMG from "../../images/TMG.png";
import Ufi from "../../images/Food_Beverages/Ufi.png";
import logo2 from "../../images/ABB.png";
import logo4 from "../../images/MisrItalia.png";
import logo5 from "../../images/Amer.png";
import logo9 from "../../images/M2.png";

// import Dolphin_Energy from "../../images/Oil_Gas/Dolphin_Energy.png";

// import logo8 from "../../images/Mej.png";
// import logo7 from "../../images/SaudiI.png";
// import Shar from "../../images/Shar.png";
// import logo3 from "../../images/Asaied.png";

function BrandCarousel() {
  let settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    cssEase: "ease-out",
    autoplay: true,
    speed: 600,
    autoplaySpeed: 1900,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 704,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="container  mt-5 mb-5 "></div>
      <Slider {...settings}>
        <div>
          <CasLogo>
            <img src={Spinneys} alt="Spinneys" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={TMG} alt="TMG" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={logo2} alt="cic logo" />
          </CasLogo>
        </div>

        <div>
          <CasLogo>
            <img src={Sanoufi} alt="Sanoufi" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={logo4} alt="cic logo" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={logo5} alt="brand logo" />
          </CasLogo>
        </div>

        <div>
          <CasLogo>
            <img src={Adidas} alt="Adidas" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={Pirell} alt="Pirell" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={Juhayna} alt="Juhayna" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={logo9} alt="cic logo" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={Iwan} alt="Iwan" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={Memaar} alt="Memaar" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={R_N} alt="R and N" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={Ufi} alt="Ufi" />
          </CasLogo>
        </div>
        <div>
          <CasLogo>
            <img src={Eltarek} alt="Eltarek" />
          </CasLogo>
        </div>
      </Slider>
    </>
  );
}
export default BrandCarousel;
