// export const homeObj={
//   primary: false,
//   lBg: true,
//   lightTopLine: false,
//   lightText: true,
//   lightTextDesc: false,
//   topLine: 'WELCOME',
//   headline:
//     'CIC',
//   description:
//     "",
//   buttonLabel: 'Read More',
//   imgStart: '',
//   img: require('../../images/TMG.png'),
//   alt: 'TMG',
//   start: 'true'
// };

//Welcome to CIC

export const homeObjOne = {
  primary: true,
  lightbg: false,
  lightTopLine: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: "Welcome To CIC",
  headline: "We Transform ",
  headline1: "Businesses",
  description:
    "Certified I.T Consultants is a leading software & implementation provider. Originally, headquartered in the U.A.E, with offices in Saudi Arabia and Egypt. CIC has become SAP’s chosen partner for software implementations across the MENA region. Furthermore, CIC was established in 2002 in Dubai and is ranked amongst the oldest local SAP partners in the region.",
  buttonLabel: "Learn More",
  imgStart: false,
  img: require("../../images/Laptop.png"),
  alt: "Vault",
  start: "",
  disp: true,
  featuredTitle: "Featured Partners",
  Pic_Width: "100%",
  destination: "/about",
};

export const homeObjTwo = {
  primary: true,
  lightbg: false,
  lightTopLine: false,
  lightText: true,
  lightTextDesc: true,
  // topLine: "Software Houses",
  headline: "Scale Your Company With Cloud Solutions",
  description:
    "At CIC, we deliver results that accelerate your company’s growth through 20 years of proven experience, effective technology and cloud solutions.",
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("../../images/Cloud.svg"),
  alt: "Credit Card",
  start: "",
  destination: "/sign-up",
};

export const homeObjThree = {
  primary: true,
  lightbg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  // topLine: "OUR CLIENTS",
  headline: "Different Sectors, Same Solution.",
  description:
    "The trust in CIC has been on a steady incline over the years. We, at CIC, have carried out projects in SAP solutions, MES and related services. Our client list consists of some of the MENA region’s most esteemed firms and companies. Our partners vary across a full range of industries. Click the link below to see all of our clients. ",
  buttonLabel: "Read More",
  imgStart: false,
  img: require("../../images/Building_.svg"),
  alt: "TMG",
  start: "",
  destination: "/Clients",
  productsColor: "#f8f8f8 ",
};

export const homeObjFour = {
  primary: true,
  lightbg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  // topLine: 'Secure Database',
  headline: "We need here Content About our Sister Companies",
  description: "Eshtri - Elemental - Rasch",
  buttonLabel: "Sign Up Now",
  imgStart: true,
  img: require("../../images/svg-3.svg"),
  alt: "Vault",
  start: "true",
  destination: "/sign-up",
};
