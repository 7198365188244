import Form from "../SignUp/Form";
import { InfoSection } from "../../components";
import React from "react";
import { homeObjOne } from "./Data";

function SignUp() {
  return (
    <>
      <InfoSection {...homeObjOne}></InfoSection>
      <Form />
      {/* <InfoSection {...homeObjThree} /> */}
    </>
  );
}

export default SignUp;
