import styled, { keyframes } from "styled-components";

import { Container } from "../../globalStyles";
import { FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import myImage from "../../images/profile.jpg";

export const Nav = styled.nav`
  background: ${({ lightbg }) => (!lightbg ? "#fff" : "#101522")};
  //background: rgb(255,255,255);
  // background:  linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(5,67,130,1) 48%, rgba(4,7,59,1) 100%); */
  // background: linear-gradient(90deg, rgba(16,21,34,1) 0%, rgba(16,21,34,1) 43%, rgba(252,176,69,1) 100%)  ;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1.2rem; */
  position: sticky;
  top: ${({ thisScroll }) => (thisScroll ? "0" : "-100%")};
  z-index: 999;
  transition: all 0.6s ease;

  @keyframes Scrolldown {
    0% {
      transform: translate(0, 0);
    }
    75% {
      transform: translate(0, -40px);
    }
    100% {
      transform: translate(0, -80px);
    }
  }
  @keyframes Scrollup {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 80px);
    }
  }
  /* .nav.active{
      background:  linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(5,67,130,1) 48%, rgba(4,7,59,1) 100%);
      top : -10px;
    } */
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 64px;

  ${Container}
`;
export const bar_anim = keyframes`
  
  0%{
    
   // width: 100px;
   // background : #101522;
   // box-shadow: 0 0 10px #101522;
    transform: rotateY(0deg);
    top: 0px;

  }
  25%{
   // width: 98px;
    /* box-shadow: 0 0 10px  none;
    background: #101522; */
    transform: rotateY(90deg);
    top: 20px;
}

  50%{
   // width: 100px;
  //  box-shadow: 0 0 10px #101522;
  //  background: #101522;
      top: 98px;
      transform: rotateY(180deg);
      

  }
  75%{
 //   background: #101522;
  //  box-shadow: 0 0 10px #101522;
 //   width: 98px;
    //transform: rotateX(0deg);
    left: 0px ;
    transform: rotateY(180deg);
  }
  100%{
//    background: #101522;
 //   box-shadow: 0 0 10px #101522;
   //transform: rotateX(180deg);
    height: 100px;
    transform: rotateY(180deg);
   // left: 0 ;
  }
 
`;
// export const LoadingSlider = styled.div`

//   height: 10px;
//   width: 10px;
//   border-radius: 5px;
//   background : aqua;
//   box-shadow: 0 0 10px aqua;
//   margin-left: 10px;
//   position: absolute;
//   margin-top: 20px ;
//   animation: ${bar_anim} 2s ease-in-out;

//   /* justify-self: flex-start;
//   cursor: pointer;
//   text-decoration: none;
//   font-size: 2rem;
//   display: flex;
//   align-items: center; */

// `
// ;

export const play = keyframes`
    0% {
        -webkit-transform:translateX(-100%) skewX(45deg);
        transform:translateX(-100%) skewX(45deg);
    } 
    30%{
        -webkit-transform:translateX(800%) skewX(45deg);
        transform:translateX(800%) skewX(45deg);
    }
    100%{
        -webkit-transform:translateX(1000%) skewX(45deg);
        transform:translateX(1000%) skewX(45deg);
    }
`;
export const NavLogo = styled(Link)`
  color: #1ea8e9;
  justify-self: flex-start;
  cursor: pointer;

  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* font-family: "Lato", sans-serif; */
  font-weight: lighter;
  font-weight: 600;

  line-height: 16px;
  font-size: 24px;

  background: rgb(0, 66, 143);
  background: linear-gradient(
    90deg,
    rgba(0, 66, 143, 1) 1%,
    rgba(33, 172, 237, 1) 100%
  );
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  padding: 1px 0;

  img {
    width: 60px;
    margin-right: 1px;
  }

  /* &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    background: #1015224f;
    background: radial-gradient(circle, snow 0%, rgba(255, 255, 255, 0.1) 100%);
    transform: skewX(45deg);
    animation: ${play} 8s ease-out infinite;
  } */
  /* &::before { */
  /* content: "";
    position: absolute;
    left: 20%;
    top: 0;
    height: 100%;
    width: 10%;
    background: #10152244;
    background: radial-gradient(circle, white, rgba(255, 255, 255, 0.1) 100%);
    transform: skewX(45deg);
    animation: ${play} 5s ease-out infinite; */
  /* } */
  @media only screen and (max-width: 600px) {
    /* font-size: 1.1rem; */
    img {
      width: 200px;
    }
  }
  @media only screen and (max-width: 960px) {
    /* font-size: 1.1rem; */
    img {
      width: 60px;
    }
  }
`;

export const NavIcon = styled.div`
  margin-right: o.5rem;
  background-image: url(${myImage});
`;

export const Pos = styled.div`
  transform: translateY(-5%);
`;
export const Navstuff = styled(FaCaretDown)`
  margin-right: 0.5rem;
  color: ${({ lightbg }) => (!lightbg ? "#101522" : "#fff")};
  //top : 10px;
  //  width : 50% ;
  // z-index : 2;

  // background-image: url(${myImage});
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    font-size: 1.3rem;
    cursor: pointer;
    color: ${({ lightbg }) => (!lightbg ? "#101522" : "#fff")};
    /* color: black; */
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 64px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: ${({ lightbg }) => (!lightbg ? "#fff" : "#101522")};
    //color: black;
  }
`;

//----------------------->BOTTOM responsive items
export const NavItem = styled.li`
  height: 40px;
  //width: 100px;
  text-align: center;
  justify-content: center;
  display: flex;
  border-bottom: 3px solid transparent;
  transition: all 0.01s ease-in;
  margin: 13px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* color: ${({ lightbg }) => (lightbg ? "black" : "#101522")}; */

  &:hover {
    color: #fff;
    transition: all 0.5s ease;
    text-align: center;
    justify-content: center;
    display: flex;

    /* animation: ${bar_anim} 2s ease-out infinite; */
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    height: auto;

    &:hover {
      border: none;
      color: blue;
      text-align: center;
      justify-content: center;
    }
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavLinks = styled(Link)`
  //El 7eta bta3et el home wel service max screeen
  color: ${({ lightbg }) => (!lightbg ? "#101522" : "#fff")};
  //color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  font-weight: 400;

  height: 100%;
  &:hover .sahm {
    color: #4b59f7;

    transition: all 0.5s ease;
    transform: rotateZ(180deg);
  }

  @media screen and (max-width: 960px) {
    //Mobile small Menu
    color: snow;
    text-align: center;
    padding: 4px 10px;
    width: 100%;
    height: 120%;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: ${({ lightbg }) => (!lightbg ? "black" : "white")};
    /* flex-direction: column; */
    // background-color: aqua;

    svg {
    }

    &:hover {
      color: #4b59f7;
      transition: all 0.5s ease;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  // color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
//------------------------------------------->Toggle
export const Switch = styled.label`
  // color: black;
  position: relative;
  display: flex;

  /* display : inline-block;
  width : 60px;
  height: 30px; */
  // background-color: aqua;
`;

export const Input = styled.input`
  position: absolute;
  left: -9999px;
  top: -9999px;
  /* opacity: 0;
      width: 0;
      height: 0; */
  //Adjacent sibling
  &:checked + span {
    background-color: #1890ff; //blue
    &:before {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
`;
export const Slyder = styled.span`
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  background-color: #bfbfbb;
  position: relative;
  transition: background-color 0.2s box-shadow 0.2s;

  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    transition: 0.2s;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }
  &:active:before {
    width: 28px;
  }
`;
