// import "bootstrap/dist/css/bootstrap.min.css";

import "../Services/Services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BOS from "../../images/Services_Images/Business-Objects-Solutions.jpg";
import Consulting from "../../images/Services_Images/SAP-Consulting.jpg";
import PPM from "../../images/Services_Images/Program-Project-Management.jpg";
import Portal from "../../images/Services_Images/SAP-Portal.jpg";
import React from "react";
import Slider from "react-slick";
import Solution from "../../images/Services_Images/SAP-SOLUTION.jpg";
import Upgrade from "../../images/Services_Images/upgrade-service.jpg";

// import { FaArrowRight } from "react-icons/fa";

// import $ from "jquery";

function Services() {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="Services_container mt-5 mb-5 carousel ">
        <div className="fs-1 text-black text-center mb-5 ">
          CIC Consulting Services
        </div>
        <Slider {...settings}>
          <div className="card-wrapper d-flex justify-content-center">
            <div className="card ">
              <div className="backgroundEffect"></div>
              <div className="pic">
                <img className="" src={Consulting} alt="SAP Consulting" />
              </div>
              <div className="content">
                <p className="h-1 mt-4">SAP Consulting</p>
                <p className="text-muted mt-3">
                  CIC’s core competency is a large pool of highly qualified SAP
                  local consultants that have a deep understanding of SAP
                  products and services. Our peer learning techniques ensure up
                  to date learning effective knowledge transfer.
                </p>
                <div className="d-flex align-items-left justify-content-left mt-3 pb-3">
                  {/* <a href="#" class="link-light">
                    Read More
                  </a> */}

                  <span className="ml-2"> </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper d-flex justify-content-center ">
            <div className="card">
              <div className="backgroundEffect"></div>
              <div className="pic">
                <img className="" src={Solution} alt="SAP-Solution" />
              </div>
              <div className="content">
                <p className="h-1 mt-4">SAP All-In-One Solutions</p>
                <p className="text-muted mt-3">
                  CIC was the first SAP local partner to be branded as an SAP
                  Channel partner. Our extensive commitment to the small and
                  midsize market and successful engagements make us the partner
                  of choice for any SAP AIO.
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3 pb-3">
                  {/* <div className="btn btn-primary">
                    Read More
                    <span className="ml-2"> </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper d-flex justify-content-center">
            <div className="card ">
              <div className="backgroundEffect"></div>
              <div className="pic">
                <img className="" src={Upgrade} alt="upgrade services" />
              </div>
              <div className="content">
                <p className="h-1 mt-4">Upgrade Services</p>
                <p className="text-muted mt-3">
                  CIC helps customers upgrade SAP older versions into the latest
                  release. We play various roles in assisting our customers
                  utilize the latest technology from SAP. Whether the customer
                  requires a technical or functional upgrade CIC is committed to
                  smooth and down time minimized upgrades.
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3 pb-3">
                  {/* <div className="btn btn-primary">
                    Read More
                    <span className="ml-2"> <FaArrowRight /></span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper d-flex justify-content-center">
            <div className="card">
              <div className="backgroundEffect"></div>
              <div className="pic">
                <img className="" src={PPM} alt="Program-Project Management" />
              </div>
              <div className="content">
                <p className="h-1 mt-4">Program & Project Management</p>
                <p className="text-muted mt-3">
                  Combining its extensive knowledge of SAP’s ASAP methodology
                  and PMI standards, CIC’s formulated project management
                  approach achieves excellence and high success ratio in all of
                  our projects.
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3 pb-3">
                  {/* <div className="btn btn-primary">
                    Read More
                    <span className="ml-2"> <FaArrowRight /></span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper d-flex justify-content-center">
            <div className="card">
              <div className="backgroundEffect"></div>
              <div className="pic">
                <img className="" src={Portal} alt="Portal" />
              </div>
              <div className="content">
                <p className="h-1 mt-4">Portal</p>
                <p className="text-muted mt-3">
                  Portals consolidate, amalgamate and distribute information
                  across and beyond organizations. A portal provides an
                  interface for people to access and exchange information
                  online.
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3 pb-3">
                  {/* <div className="btn btn-primary">
                    Read More
                    <span className="ml-2"> <FaArrowRight /></span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-wrapper d-flex justify-content-center">
            <div className="card ">
              <div className="backgroundEffect"></div>
              <div className="pic">
                <img className="" src={BOS} alt="business solutions" />
              </div>
              <div className="content">
                <p className="h-1 mt-4">Business Objects Solutions</p>
                <p className="text-muted mt-3">
                  Following SAP’s acquisition of Business Objects, CIC has
                  invested in building a pool of BOBJ consultants through
                  acquiring such consultants and combining them with our
                  existent SAP BI team. Moreover, CIC also supports Business
                  Objects EPM, and GRC Solutions.
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3 pb-3">
                  {/* <div className="btn btn-primary">
                    Read More
                    <span className="ml-2"> <FaArrowRight /></span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}
export default Services;
