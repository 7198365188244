export const homeObjOne = {
  lightbg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "Our Products",
  headline:
    "Help streamline your business processes by discovering our Certified add-ons by ",
  headline1: "SAP.",
  // description: "loeencw dnewc oidnqoenid oidnqwn oindind oqwndnq nwdq",
  buttonLabel: "Shop Now",
  imgStart: "1",
  img: require("../../images/Products.png"),
  alt: "Credit Card",
  productsColor: "#f8f8f8",
  Pic_Width: "500px",
  dispBtn: "1",
};
