export default function validateInfo(values) {
  let errors = {};

  if (!values.username) {
    errors.username = "Name is required*";
  }
  // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
  //   errors.name = 'Enter a valid name';
  // }

  if (!values.email) {
    errors.email = "Email required*";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  if (!values.company_name) {
    errors.company_name = "Company name is required*";
  }

  if (!values.phone) {
    errors.phone = "Phone No. is required*";
  }
  if (!values.subject) {
    errors.subject = "Subject is required*";
  }
  if (!values.message) {
    errors.message = "Message is required";
  }
  //  else if (values.password2 !== values.password) {
  //   errors.password2 = 'Passwords do not match';
  // }
  return errors;
}
