// export const homeObjOne = {
//   lightbg: false,
//   lightText: true,
//   lightTextDesc: true,
//   topLine: "Exclusive Access",
//   headline: "Unlimited Transactions with zero fees",
//   description:
//     "Get access to our exclusive diamond card that allows you to  send unlimited transactions without getting charged any fees",
//   buttonLabel: "Get Started",
//   imgStart: "",
//   img: require("../../images/svg-1.svg"),
//   alt: "Credit Card",
// };

// export const homeObjTwo = {
//   lightbg: false,
//   lightText: true,
//   lightTextDesc: true,
//   topLine: "100% Secure",
//   headline: "Stay protected 24/7 anywhere anytime",
//   description:
//     "We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime",
//   buttonLabel: "Learn More",
//   imgStart: "",
//   img: require("../../images/svg-1.svg"),
//   alt: "Vault",
// };

export const homeObjThree = {
  lightbg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: "Our services",
  headline: "A simpler and faster onboarding process.",
  description:
    "Our aim is to get our partners to completely leverage their I.T investments, which is why our wide-ranging services map the application lifecycle of our customers. Through years of exposure to different industries, we have accumulated the experience that allows us to provide effective end-to-end I.T solutions to our clients.",
  buttonLabel: "Start Now",
  imgStart: "start",
  img: require("../../images/tech.svg"),
  alt: "Vault",
};

// export const homeObjFour = {
//   lightbg: false,
//   lightText: true,
//   lightTextDesc: true,
//   topLine: "Data Analytics",
//   headline: "Every transaction is stored on our secure cloud database",
//   description:
//     "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
//   buttonLabel: "Sign Up Now",
//   imgStart: "start",
//   img: require("../../images/svg-1.svg"),
//   alt: "Vault",
// };
