export const homeObjOne = {
  lightbg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "Contact Us ",
  headline: "We Love To Hear From You.",
  description:
    "Let's get in touch and know your feedback, fill the form down below and we will contact you.",
  buttonLabel: "CIC Training",
  imgStart: "1",
  img: require("../../images/svg-1.svg"),
  alt: "contact us",
  dispBtn: "1",
};

// export const homeObjTwo = {
//   lightbg: true,
//   lightText: true,
//   lightTopLine: true,
//   lightTextDesc: true,
//   topLine: "100% Secure",
//   headline: "Stay protected 24/7 anywhere anytime",
//   description:
//     "We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime",
//   buttonLabel: "Learn More",
//   imgStart: "",
//   img: require("../../images/svg-1.svg"),
//   alt: "Vault",
// };

export const homeObjThree = {
  lightbg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: "Easy Setup",
  headline: "Super fast and simple onboarding process",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};

// export const homeObjFour = {
//   lightbg: false,
//   lightText: true,
//   lightTopLine: true,
//   lightTextDesc: true,
//   topLine: "Data Analytics",
//   headline: "Every transaction is stored on our secure cloud database",
//   description:
//     "Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
//   buttonLabel: "Sign Up Now",
//   imgStart: "start",
//   img: require("../../images/svg-1.svg"),
//   alt: "Vault",
// };
