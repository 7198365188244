export const MenuItems = [
  {
    title: "Partnership",
    path: "/partners",
    cName: "dropdown-link",
  },
  {
    title: "Our Clients",
    path: "/Clients",
    cName: "dropdown-link",
  },
  {
    title: "About Us",
    path: "/about",
    cName: "dropdown-link",
  },
  // {
  //   title: 'Development',
  //   path: '/development',
  //   cName: 'dropdown-link'
  // }
];
