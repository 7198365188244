import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Footer, Navbar } from "./components";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AOS from "aos";
import About from "./pages/About/About";
import Clients from "./pages/ClientsPage/Clients";
import GlobalStyle from "./globalStyles";
import Home from "./pages/HomePage/Home";
import Partners from "./pages/PartnersPage/PartnersPage";
import Products from "./pages/Products/ProductsPage";
import React from "react";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./pages/Services/Services";
import SignUp from "./pages/SignUp/SignUp";

// import "../src/global.css";

function App() {
  const [isDarkMode, setDarkMode] = React.useState(0);

  React.useEffect(() => {
    // console.log("zreb")
    AOS.init();
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar isDarkMode={isDarkMode} setDarkMode={setDarkMode} />
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => <Home {...props} isDarkMode={isDarkMode} />}
        />
        <Route
          path="/services"
          render={(props) => <Services {...props} isDarkMode={isDarkMode} />}
        />
        <Route
          path="/products"
          render={(props) => <Products {...props} isDarkMode={isDarkMode} />}
        />
        <Route path="/about" component={About} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/Clients" component={Clients} />
        <Route path="/Partners" component={Partners} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
