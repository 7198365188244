import "../PartnersPage/PartnersPage.css";

import React from "react";

function Partners() {
  return (
    <>
      {/* <input type="checkbox" id="debug" /> */}

      <div class="text-center mt-5 mb-5 fs-1 text-primary">
        <h1>CIC Partnership Dimensions With SAP</h1>
      </div>

      <main class="p-5">
        <ul id="partners_cards">
          <li class="partners_card" id="card_1">
            <div class="card__content">
              <div>
                <h2> SAP Business All-in-One</h2>
                <p>
                  {" "}
                  The Sap Business All-in-One fast-start program helps you
                  select and rapidly deploy complete business management
                  solution.
                </p>
                {/* <p>
                  <a href="#top" class="btn btn--accent">
                    Read more
                  </a>
                </p> */}
              </div>
              <figure>
                <img src="images/SAP-Business-All-in-One.jpg" alt="Sap sol" />
              </figure>
            </div>
          </li>
          <li class="partners_card" id="card_2">
            <div class="card__content">
              <div>
                <h2>SAP Business Objects</h2>
                <p>
                  {" "}
                  We offer customized solutions – essentially, a combination of
                  domain, vertical, or application expertise that includes SAP
                  Business Objects technology.
                </p>
                {/* <p>
                  <a href="#top" class="btn btn--accent">
                    Read more
                  </a>
                </p> */}
              </div>
              <figure>
                <img src="images/SAP-Business-Objects.jpg" alt="Sap sol" />
              </figure>
            </div>
          </li>
          <li class="partners_card" id="card_3">
            <div class="card__content">
              <div>
                <h2> Service Partner</h2>
                <p>
                  {" "}
                  We help SAP customers design, implement, and integrate SAP
                  solutions; optimize business processes; and provide strategic
                  business consultation.
                </p>
                {/* <p>
                  <a href="#top" class="btn btn--accent">
                    Read more
                  </a>
                </p> */}
              </div>
              <figure>
                <img src="images/SAP-channel-partner.jpg" alt="Sap sol" />
              </figure>
            </div>
          </li>
          <li class="partners_card" id="card_4">
            <div class="card__content">
              <div>
                <h2>SAP Certified in SAP HANA Enterprise Cloud</h2>
                <p>
                  {" "}
                  SAP HANA converges database and application platform
                  capabilities in-memory to transform transactions, analytics,
                  text analysis, predictive and spatial processing so businesses
                  can operate in real-time.
                </p>
                {/* <p>
                  <a href="#top" class="btn btn--accent">
                    Read more
                  </a>
                </p> */}
              </div>
              <figure>
                <img src="images/SAP-HANA-Enterprise-Cloud.jpg" alt="Sap sol" />
              </figure>
            </div>
          </li>
        </ul>
      </main>

      {/* <p className="container fs-4">
        CIC is the first SAP local partner with two categories of partnership
        with SAP; Service Partnership (Large Enterprises), and Channel
        Partnership (Small and Midsize Businesses).
      </p> */}
    </>
  );
}
export default Partners;
