import logo from "../../images/Laptop.png";
import styled from "styled-components";

export const SignupSec = styled.div`
  color: #fff;
  padding: 120px 0;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${logo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; */
  /* max-width: 1350px; */
  left: auto;
  margin: auto;
  width: 100%;
  /* border-radius: 24px; */
  margin-bottom: 90px;
  @media screen and (max-width: 768px) {
    border-radius: 0px;
  }
`;

export const SignupRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  /* flex-direction: ${({ contactStart }) =>
    contactStart ? "row-reverse" : "row-reverse"}; */
  flex-direction: row;
`;

export const SignupColumn = styled.div`
  /* margin-bottom: 15px; */
  /* padding-right: 15px;
  padding-left: 15px; */
  flex: 1;
  /* max-width: 100%; */
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const Form = styled.form`
  display: flex;
  margin-bottom: 20px;
  width: 98%;
  flex-wrap: wrap;
  align-items: flex-start;

  p {
    margin-bottom: 5px;
    color: black;
    font-size: 14px;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 90%;
    align-items: stretch;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 24px;
  outline: none;
  font-size: 16px;
  border: ${({ borderco }) =>
    borderco ? `2px solid red` : "1.5px solid #000"};
  max-width: 700px;
  border-radius: 5px;
  margin-top: 20px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    /* width: 580px; */
    margin: 0 0 7px 0;
    padding-left: 5px;
    /* max-width: 580px; */
  }
  &::placeholder {
    color: #242424;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (max-width: 820px) {
    flex-direction: column;
    /* width: 580px; */

    /* max-width: 580px; */
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* padding: 30px 20px; */
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
export const CommentTextArea = styled.textarea`
  padding: 9px 12px;
  border-radius: 2px;
  margin-right: 24px;
  outline: none;
  font-size: 16px;
  border: ${({ borderco }) =>
    borderco ? `2px solid red` : "1.5px solid #000"};
  max-width: 700px;
  width: 465px;
  height: 135px;
  border-radius: 5px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 346px;
    margin: 0 0 7px 0;
    padding-left: 5px;
    /* max-width: 580px; */
  }
  &::placeholder {
    /* position: absolute; */
    color: #242424;
    width: 100%;
  }
`;
export const CommentInput = styled.input`
  padding: 9px 12px;
  border-radius: 2px;
  margin-right: 24px;
  outline: none;
  font-size: 16px;
  border: 1.5px solid #000;
  max-width: 700px;
  width: 490px;
  height: 150px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    /* width: 580px; */
    margin: 0 0 7px 0;
    padding-left: 5px;
    /* max-width: 580px; */
  }
  &::placeholder {
    /* position: absolute; */
    color: #242424;
    width: 100%;
  }
`;

export const SignupHolder = styled.div`
  /* max-width: 555px; */
  display: flex;
  align-items: flex-end;

  justify-content: space-between;
  flex-wrap: wrap;
`;
export const TextWrapper = styled.div`
  /* max-width: 540px; */
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const TextWrapper2 = styled.div`
  max-width: 540px;
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? "#a9b3c1" : "#4B59F7")};
  font-size: 20px;
  /* line-height: 16px; */
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 35px;
  /* line-height: 16px; */
  font-weight: 600;
  color: #000;
`;

export const Subtitle = styled.p`
  max-width: 940px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  /* opacity: 75%; */
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#000" : "#1c2237")};
`;

export const ContactInfo = styled.div`
  /* position: relative; */
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? "#000" : "#1c2237")};
  max-width: 555px;
  justify-content: flex-start;
`;

export const ContactHeading = styled.h4`
  margin-bottom: 4px;
  font-size: 24px;
  /* line-height: 16px; */
  font-weight: 400;
  color: #000;
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #000;
  font-size: 24px;
  padding: 10px;
`;
