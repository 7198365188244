import {
  Col_row,
  CounterHolder,
  Counters_caption,
  Counters_item,
  Counters_numbervalue,
  Counters_value,
} from "./CocounterElements";
import React, { useEffect } from "react";

import CountUp from "react-countup";
import Icon1 from "../../images/experience.png";
import Icon2 from "../../images/customer.png";
import Icon3 from "../../images/consultant.png";

function Cocounter({ lightbg }) {
  //const [mode, setMode] = useState(getMode);
  //const [isClicked, setClicked] = useState(false);

  useEffect(() => {
    let clicked = false;
    document.addEventListener("scroll", (e) => {
      //   console.log(document.getBoundingClientRect().y );
      if (!document.getElementById("counterHolder1")) return;
      if (
        document.getElementById("counterHolder1").getBoundingClientRect().y <
          700 &&
        !clicked
      ) {
        clicked = true;
        document.getElementById("countBtn").click();
        document.getElementById("countBtn1").click();
        document.getElementById("countBtn2").click();
        //setTimeout
        //clicked = false;
      }
    });
  }, []);
  return (
    <>
      <CounterHolder id="counterHolder1" lightbg={lightbg}>
        <Col_row lightbg={!lightbg}>
          <Counters_item>
            <img src={Icon1} alt="icon"></img>
            <Counters_value className={"counters-item-value"}>
              <Counters_numbervalue>
                <Counters_caption>
                  <CountUp
                    start={0}
                    end={20}
                    duration={3.5}
                    // separator=""
                    decimals={0}
                    decimal=","
                    // prefix="Count  : "
                    suffix="+"
                    // onEnd={() => console.log('Ended! 👏')}
                    // onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />

                        <button
                          style={{ display: "none" }}
                          id="countBtn"
                          onClick={start}
                        >
                          Start
                        </button>
                      </div>
                    )}
                  </CountUp>
                </Counters_caption>
              </Counters_numbervalue>
            </Counters_value>
            <p className="lh-sm p-2 ">
              {" "}
              <strong>Years Experience in SAP solutions</strong>{" "}
            </p>
          </Counters_item>

          <Counters_item>
            <img src={Icon3} alt="icon"></img>
            <Counters_value className={"counters-item-value"}>
              <Counters_numbervalue>
                <Counters_caption>
                  <CountUp
                    start={0}
                    end={120}
                    duration={3.5}
                    // separator=""
                    decimals={0}
                    decimal=","
                    // prefix="Count  : "
                    suffix="+"
                    // onEnd={() => console.log('Ended! 👏')}
                    // onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />

                        <button
                          style={{ display: "none" }}
                          id="countBtn1"
                          onClick={start}
                        >
                          Start
                        </button>
                      </div>
                    )}
                    {/* <path d="M17.846 14.826h-4.924a1.846 1.846 0 00-1.844 1.846v12.307c0 1.019.825 1.845 1.844 1.847h4.924a1.85 1.85 0 001.847-1.847V16.672a1.85 1.85 0 00-1.847-1.846z" fill="url(#graphic_svg__e)" transform="translate(2 3)"></path> */}
                  </CountUp>
                </Counters_caption>
              </Counters_numbervalue>
            </Counters_value>
            <p className="lh-sm p-2">
              <strong> Arabic Speaking Consultants</strong>
            </p>
          </Counters_item>

          <Counters_item>
            <img src={Icon2} alt="icon"></img>
            <Counters_value className={"counters-item-value"}>
              <Counters_numbervalue>
                <Counters_caption>
                  <CountUp
                    start={0}
                    end={80}
                    duration={3.5}
                    // separator=""
                    decimals={0}
                    decimal=","
                    // prefix="Count  : "
                    suffix="+"
                    // onEnd={() => console.log('Ended! 👏')}
                    // onStart={() => console.log('Started! 💨')}
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />

                        <button
                          style={{ display: "none" }}
                          id="countBtn2"
                          onClick={start}
                        >
                          Start
                        </button>
                      </div>
                    )}
                    {/* <path d="M17.846 14.826h-4.924a1.846 1.846 0 00-1.844 1.846v12.307c0 1.019.825 1.845 1.844 1.847h4.924a1.85 1.85 0 001.847-1.847V16.672a1.85 1.85 0 00-1.847-1.846z" fill="url(#graphic_svg__e)" transform="translate(2 3)"></path> */}
                  </CountUp>
                </Counters_caption>
              </Counters_numbervalue>
            </Counters_value>{" "}
            <p className="lh-sm p-2 ">
              {" "}
              <strong>
                Enterprise level customers in the public & private sector{" "}
              </strong>
            </p>
          </Counters_item>
        </Col_row>
      </CounterHolder>
    </>
  );
}
export default Cocounter;
