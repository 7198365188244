import { BrandCarousel, Cocounter, InfoSection } from "../../components";
import { homeObjOne, homeObjThree, homeObjTwo } from "./Data";

import React from "react";
import Services from "../../components/Services/Services";

function Home(props) {
  // console.log(props);

  return (
    <>
      {/* <MainSlider /> */}
      {/* <InfoSection {...homeObj} /> */}

      <InfoSection
        {...homeObjOne}
        lightbg={!props.isDarkMode}
        lightText={props.isDarkMode}
        lightTextDesc={props.isDarkMode}
        imgStart={!props.isDarkMode}
      />
      <InfoSection
        {...homeObjThree}
        lightbg={!props.isDarkMode}
        lightText={props.isDarkMode}
        lightTextDesc={props.isDarkMode}
      />
      <InfoSection
        {...homeObjTwo}
        lightbg={!props.isDarkMode}
        lightText={props.isDarkMode}
        lightTextDesc={props.isDarkMode}
      />
      <Cocounter lightbg={!props.isDarkMode} />
      <BrandCarousel />

      {/* <Pricing lightbg={!props.isDarkMode} /> */}

      {/* <InfoSection
        {...homeObjFour}
        lightbg={!props.isDarkMode}
        lightText={props.isDarkMode}
        lightTextDesc={props.isDarkMode}
      /> */}

      <Services />
    </>
  );
}

export default Home;
