import "../AboutComponents/Our_Leadership.css";

import React from "react";

// import "bootstrap/dist/css/bootstrap.min.css";

function Our_Leadership() {
  return (
    <div className="container">
      <div className="row justify-content-center  ">
        <div className="col-md-4  col-sm-6">
          <div className="box ">
            <img src="images/mr_ibrahim.jpg" alt="CIC_Manager" />
            <div className="box-content">
              <div className="overlay-img">
                <img src="images/mr_ibrahim.jpg" alt="CIC_Manager" />
              </div>
              <div className="inner-content">
                <h3 className="title">Ibrahim Ahmed</h3>
                <span className="post">Co-Chairman & CEO</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-4">
          <div className="box">
            <img src="images/HeshamElganainy.jpg" alt="CIC_Manager" />
            <div className="box-content">
              <div className="overlay-img">
                <img src="images/HeshamElganainy.jpg" alt="CIC_Manager" />
              </div>
              <div className="inner-content">
                <h3 className="title">Hesham ElGanainy</h3>
                <span className="post">Co-Chairman & CEO</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center  ">
        <div className="col-md-4 col-sm-6">
          <div className="box">
            <img src="images/wagdi.jpg" alt="Wajdi Saif" />
            <div className="box-content">
              <div className="overlay-img">
                <img src="images/wagdi.jpg" alt="Wajdi Saif" />
              </div>
              <div className="inner-content">
                <h3 className="title">Wajdi Saif</h3>
                <span className="post">PMO Manager</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div className="box">
            <img src="images/ibraEissa.jpg" alt="CIC_Manager" />
            <div className="box-content">
              <div className="overlay-img">
                <img src="images/ibraEissa.jpg" alt="CIC_Manager" />
              </div>
              <div className="inner-content">
                <h3 className="title">Ibrahim Eisa</h3>
                <span className="post">CTO</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Our_Leadership;
