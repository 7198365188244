import { homeObjOne, homeObjThree } from "./Data";

import { InfoSection } from "../../components";
import React from "react";
import Services_page from "../../components/Services/Services";

function Services(props) {
  return (
    <>
      <InfoSection
        {...homeObjThree}
        lightbg={!props.isDarkMode}
        lightText={props.isDarkMode}
        lightTextDesc={props.isDarkMode}
      />
      <Services_page />
    </>
  );
}

export default Services;
