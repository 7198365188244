import { FaBars, FaMoon, FaSun, FaTimes } from "react-icons/fa";
import {
  Input,
  MobileIcon,
  Nav,
  NavBtnLink,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavLogo,
  NavMenu,
  NavbarContainer,
  Navstuff,
  Pos,
  Slyder,
  Switch,
} from "./Navbar.elements";
import React, { useEffect, useState } from "react";

import { Button } from "../../globalStyles";
import Dropdown from "../DropDown/Dropdown";
import { IconContext } from "react-icons/lib";
import logo from "../../images/cic-logo-final-11.svg";
import { useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";

function Navbar({ isDarkMode, setDarkMode }) {
  const { pathname } = useLocation();

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [dropDown, setDropdown] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  //ShowButtons if screen is big
  const showButton = () => {
    var prevScrollpos = window.pageYOffset;
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    // console.log(navigator);
    showButton();
  }, []);

  window.addEventListener("resize", showButton);
  var prevScrollpos = window.pageYOffset;
  window.addEventListener("scroll", (res) => {
    var scrollTop = window.pageYOffset;
    if (prevScrollpos >= scrollTop) {
      setScroll(true);
    } else {
      setScroll(false);
    }
    prevScrollpos = scrollTop;
    //removeNav();
  });
  return (
    <>
      <IconContext.Provider value={{}}>
        <Nav thisScroll={scroll} lightbg={isDarkMode}>
          <NavbarContainer>
            {/* <Navstuff/> */}
            <NavLogo to="/" onClick={closeMobileMenu}>
              <img src={logo} alt="cic logo" />
              {/* CIC */}
              {/* <div>
                <span>Certified IT Consultants</span>
                <span> Consultants</span>
              </div> */}
            </NavLogo>
            <MobileIcon onClick={handleClick} lightbg={isDarkMode}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click} lightbg={isDarkMode}>
              <NavItem lightbg={isDarkMode}>
                <NavLinks lightbg={isDarkMode} to="/" onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  lightbg={isDarkMode}
                  to="/services"
                  onClick={closeMobileMenu}
                >
                  Services
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  lightbg={isDarkMode}
                  to="/products"
                  onClick={closeMobileMenu}
                >
                  Products
                </NavLinks>
              </NavItem>

              <NavItem
                onClick={() => setDropdown(!dropDown)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <NavLinks
                  lightbg={isDarkMode}
                  to="/about"
                  onClick={(e) => {
                    if (!dropDown) {
                      e.preventDefault();
                      e.stopPropagation();
                      setDropdown(true);
                    } else {
                    }
                  }}
                >
                  {/* <Link> */}
                  About <Navstuff lightbg={isDarkMode} className="sahm" />
                  {/* </Link> */}
                </NavLinks>
                {dropDown && <Dropdown />}
              </NavItem>

              <NavItemBtn>
                {button ? (
                  <NavBtnLink to="/sign-up">
                    <Button
                      btnColor="linear-gradient(165deg, rgba(0,67,146,1) 3%, rgba(33,174,238,1) 100%)"
                      borderColor="#0467FB"
                    >
                      Contact us
                    </Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to="/sign-up">
                    <Button onClick={closeMobileMenu} fontBig primary>
                      Contact us
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
              {document.getElementById("cic_dark_mode") ? (
                <Switch
                  onChange={() => {
                    setDarkMode(isDarkMode ? 0 : 1);
                  }}
                >
                  <Pos>
                    {" "}
                    {!isDarkMode ? (
                      <FaSun />
                    ) : (
                      <FaMoon color={"white"} top={"20px"} />
                    )}{" "}
                  </Pos>
                  <Input type="checkbox" />
                  <Slyder />
                </Switch>
              ) : (
                <></>
              )}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
